import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link as RLink } from "@reach/router"
import { createUseStyles } from "react-jss"

import { borders, borderRadius, sizes } from "../style/design-system"

const useStyles = createUseStyles({
  container: {
    border: borders["regularBold"],
    borderRadius: borderRadius["lg"],
  },
  header: {
    display: `flex`,
    alignItems: `baseline`,
    justifyContent: `space-between`,
    padding: sizes["md"],
    borderBottom: borders["regularBold"],
  },
  title: {
    margin: 0,
  },
  body: {
    display: "flex",
    padding: sizes["md"],
    height: 240,
  },
  halfList: {
    width: `50%`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-between`,
  },
})

export default ({ className }) => {
  const classes = useStyles()

  const satser = useStaticQuery(
    graphql`
      query SatserQuery {
        allHitsJson(
          filter: {
            category: { eq: "kontering" }
            hitblock: { eq: "Satser" }
            title: {
              in: [
                "Aktivering"
                "Arbeidsgiveravgift"
                "Avskrivninger / saldogrupper"
                "Bilgodtgjørelse"
                "Forsinkelsesrenter"
                "Gaver i arbeidsforhold"
                "Normrentesatser"
                "Merverdiavgiftsatser"
                "Representasjon"
              ]
            }
          }
          sort: { fields: [title], order: ASC }
        ) {
          edges {
            node {
              title
              slug
            }
          }
        }
      }
    `
  )

  let middle = Math.ceil(satser.allHitsJson.edges.length / 2)
  let satserOne = satser.allHitsJson.edges.slice(0, middle)
  let satserTwo = satser.allHitsJson.edges.slice(middle)

  console.log(satser)
  return (
    <div className={[className, classes.container].join(` `)}>
      <div className={classes.header}>
        <h3 className={classes.title}>Satser</h3>
        <RLink style={{ textDecoration: `none` }} to="/kontering/satser">
          Vis alle satser
        </RLink>
      </div>
      <div className={classes.body}>
        <div className={classes.halfList}>
          {satserOne.map(({ node }, i) => (
            <React.Fragment key={i}>
              <RLink
                style={{ textDecoration: `none`, color: `inherit` }}
                to={`/${node.slug}`}
              >
                {node.title}
              </RLink>
            </React.Fragment>
          ))}
        </div>
        <div className={classes.halfList}>
          {satserTwo.map(({ node }, i) => (
            <React.Fragment key={i}>
              <RLink
                style={{ textDecoration: `none`, color: `inherit` }}
                to={`/${node.slug}`}
              >
                {node.title}
              </RLink>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}
