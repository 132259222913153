import { useState, useEffect } from "react"
import format from "date-fns/format"

export const useExchangeRates = (initBase, symbolData) => {
  const [base, setBase] = useState(initBase)
  const [rates, setRates] = useState(null)
  const [date, setDate] = useState(new Date())

  const symbols = symbolData.map(x => x.symbol).join(`,`)

  useEffect(() => {
    fetch(
      `https://data.fixer.io/api/${format(date, `yyyy-MM-dd`)}?access_key=${
        process.env.FIXER_IO_ACCESS_KEY
      }&base=${base}&symbols=${symbols}&format=1`
    )
      .then(result => {
        if (result.ok) return result.json()
      })
      .then(json => {
        setRates(json.rates)
      })
      .catch(error => {
        console.log(error)
      })
  }, [base, date])

  return { base, setBase, rates, date, setDate }
}
